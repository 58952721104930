import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import HelpOutlineIcon from '@mui/icons-material/InfoOutlined'
import TooltipIcon from '../../../../components/TooltipIcon'
import { useGetSwipesFrequenciesHourlyQuery } from '../../../../generated/graphql'
import useGame from '../../../../hooks/useGame'
import { useTheme } from '../../../../theme'
import BasicWidget from '../../Stats/components/widgets/BasicWidget'

const FrequencyTimeWidget = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const game = useGame()

  const { data: swipeFrequencyHourlyData } = useGetSwipesFrequenciesHourlyQuery(
    { input: { gameId: game?.id } },
    { enabled: !!game?.id },
  )
  const swipesFrequenciesHourly = swipeFrequencyHourlyData?.adminGetSwipesFrequenciesHourly

  const mostSwipesTime = useMemo(
    () =>
      swipesFrequenciesHourly &&
      Math.max(...(Object.values(swipesFrequenciesHourly).filter((n) => n !== 'SwipesFrequenciesHourly') as any[])),
    [swipesFrequenciesHourly],
  )
  const highestTime =
    swipesFrequenciesHourly &&
    Object.keys(swipesFrequenciesHourly).find((key) => swipesFrequenciesHourly[key] === mostSwipesTime)

  const leastSwipesTime = useMemo(
    () =>
      swipesFrequenciesHourly &&
      Math.min(...(Object.values(swipesFrequenciesHourly).filter((n) => n !== 'SwipesFrequenciesHourly') as any[])),
    [swipesFrequenciesHourly],
  )
  const lowestTime =
    swipesFrequenciesHourly &&
    Object.keys(swipesFrequenciesHourly).find((key) => swipesFrequenciesHourly[key] === leastSwipesTime)

  return (
    <BasicWidget
      title={lowestTime ? t(`stats.hours.${highestTime}`) : ''}
      subtitle={t('stats.highestFrequencyTime')}
      iconRight={
        <TooltipIcon
          text={t('stats.tooltips.frequencyTime')}
          icon={<HelpOutlineIcon fontSize={'small'} sx={{ color: theme.palette.text.secondary }} />}
        />
      }
      chipLabel={`${lowestTime ? t(`stats.hours.${lowestTime}`) : ''} ${t('stats.lowestFrequencyTime')}`}
    />
  )
}

export default FrequencyTimeWidget
