import { useTranslation } from 'react-i18next'
import HelpOutlineIcon from '@mui/icons-material/InfoOutlined'
import numeral from 'numeral'
import TooltipIcon from '../../../../components/TooltipIcon'
import { useGetBeatBoxSwipesQuery, useGetLocationFrequencyQuery } from '../../../../generated/graphql'
import useGame from '../../../../hooks/useGame'
import { useTheme } from '../../../../theme'
import BasicWidget from '../../Stats/components/widgets/BasicWidget'

const BeatBoxSwipesWidget = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const game = useGame()

  const { data: beatBoxSwipesData } = useGetBeatBoxSwipesQuery(
    { input: { gameId: game?.id } },
    { enabled: Boolean(game?.id) },
  )
  const beatBoxSwipes = beatBoxSwipesData?.adminGetBeatBoxSwipes

  const { data: locationFrequencyData } = useGetLocationFrequencyQuery(
    { input: { gameId: game?.id } },
    { enabled: !!game?.id },
  )
  const locationFrequency = locationFrequencyData?.adminGetLocationFrequency

  return (
    <BasicWidget
      title={`${numeral(locationFrequency?.[0]?.visits).format('0,0')} visits`}
      subtitle={t('stats.highestSwipedBeatBox')}
      iconRight={
        <TooltipIcon
          text={t('stats.tooltips.highestSwipedBeatBox')}
          icon={<HelpOutlineIcon fontSize={'small'} sx={{ color: theme.palette.text.secondary }} />}
        />
      }
      chipLabel={t('stats.averageSwipesPerBeatBox', {
        number: numeral(beatBoxSwipes?.total / locationFrequency?.length).format('0,0'),
      })}
    />
  )
}

export default BeatBoxSwipesWidget
