import { useRef } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Box, Container, Grid, Typography, useTheme } from '@mui/material'
import useSettings from '../../../hooks/useSettings'
import BeatBoxSwipesWidget from './components/BeatBoxSwipesWidget'
import FrequencyDayWidget from './components/FrequencyDayWidget'
import FrequencyTimeWidget from './components/FrequencyTimeWidget'
import GameMileageWidget from './components/GameMileageWidget'
import LocationsTable from './components/LocationsTable'
import SwipesChart from './components/SwipesChart'
import TotalCardsWidget from './components/TotalCardsWidget'
import TotalSwipesWidget from './components/TotalSwipesWidget'

const Activity = () => {
  const { t } = useTranslation()
  const { settings } = useSettings()
  const theme = useTheme()
  const anchorRef = useRef(null)

  return (
    <>
      <Helmet>
        <title>{t('navigation.activity')}</title>
      </Helmet>
      <Box
        sx={{
          minHeight: '100%',
          py: 4,
          backgroundColor: 'background.default',
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Grid item>
              <Typography
                ref={anchorRef}
                color={theme.palette.text.secondary}
                variant={'h5'}
                component={'p'}
                sx={{ display: 'inline' }}
              >
                {t('navigation.activity')}
              </Typography>
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-start" spacing={2} my={2} alignItems="stretch">
            <Grid item lg={6} xs={12}>
              <Grid container justifyContent="flex-start" spacing={2} alignItems="stretch">
                <Grid item sm={6} xs={12}>
                  <TotalSwipesWidget />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <GameMileageWidget />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <BeatBoxSwipesWidget />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TotalCardsWidget />
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={6} xs={12}>
              <LocationsTable />
            </Grid>

            <Grid item xl={9} lg={8} xs={12}>
              <SwipesChart />
            </Grid>
            <Grid container item xl={3} lg={4} sm={12} justifyContent="flex-start" spacing={2} alignItems="stretch">
              <Grid item lg={12} md={6} sm={6} xs={12}>
                <FrequencyTimeWidget />
              </Grid>
              <Grid item lg={12} md={6} sm={6} xs={12}>
                <FrequencyDayWidget />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
}

export default Activity
